export default {
  init() {
    // JavaScript to be fired on all pages
    // console.log('common');
  },
  finalize() {
    // console.log('common finalize');

    document.addEventListener("DOMContentLoaded", function () {
      /* Open menu */
      var menuToggle = document.querySelector(".menu-toggle");
      var menuWrapperItems = document.querySelectorAll(".menu-wrapper li");
      menuToggle.addEventListener("click", function () {
        document.body.classList.toggle("menu-open");
        menuWrapperItems.forEach(function (item, i) {
          setTimeout(function () {
            item.classList.toggle("loaded", !item.classList.contains("loaded"));
          }, 90 * i);
        });
      });

      /* Close menu on big screen */
      if (window.innerWidth > 991) {
        document.body.classList.remove("menu-open");
      }

      const header = document.querySelector("header");
      const headerInner = document.querySelector(".header__inner");
      const mainElement = document.querySelector("main.hubspot");

      if (mainElement && headerInner) {
        headerInner.classList.add("hubspot-active");
      }

      function checkScroll() {
        const headerBanner = document.querySelector(".js-header-banner");
        const topOffsetForFixedHeader = headerBanner
          ? headerBanner.getBoundingClientRect().height
          : 10;

        if (window.scrollY >= topOffsetForFixedHeader) {
          header.classList.add("scrolled");
          headerInner.classList.add("scrolled");
        } else {
          header.classList.remove("scrolled");
          headerInner.classList.remove("scrolled");
        }
      }

      checkScroll();
      window.addEventListener("scroll", checkScroll);
    });

    /**
     * Video block - start video on click img
     */
    const videoImages = document.querySelectorAll(".video-image");

    videoImages.forEach(function (videoImage) {
      videoImage.addEventListener("click", function (ev) {
        videoImage.classList.add("hidden");
        videoImage.parentElement.classList.add("playing");
        
        const iframe = videoImage.previousElementSibling.querySelector("iframe");
        const video = videoImage.previousElementSibling.querySelector("video");
        
        if (iframe) {
          iframe.src += "&autoplay=1";
          iframe.classList.add("playing");
        }
        
        if (video) {
          video.play();
          video.classList.add("playing");
        }

        // Hide "videoText" on video start
        const videoText = document.getElementById("videoText");
        if (videoText) {
          videoText.style.display = "none";
        }
        ev.preventDefault();
      });
    });

    // Check if autoplay is true and add playing class to the iframe
  const videoWrappers = document.querySelectorAll(".video-wrapper");

  videoWrappers.forEach(function (videoWrapper) {
    const iframe = videoWrapper.querySelector("iframe");
    if (iframe && iframe.src.includes("autoplay=1")) {
      videoWrapper.classList.add("playing");
      iframe.classList.add("playing");
    }
  });

    /**
     * Ajax load more.
     */
    document.addEventListener("click", function (event) {
      var target = event.target;

      if (target.classList.contains("js-load-more")) {
        event.preventDefault();
        var me = target;

        var data = {
          action: "load_more",
          posttype: me.getAttribute("data-posttype"),
          no_posts: parseInt(me.getAttribute("data-posts")),
          no_posts_loaded: parseInt(me.getAttribute("data-postsloaded")),
          post_term_id: me.getAttribute("data-term"),
        };

        me.setAttribute("disabled", true);
        me.classList.add("button--loading");
        me.textContent = "Laddar...";

        fetch(adminAjaxURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(data),
        })
          .then(function (response) {
            return response.text();
          })
          .then(function (response) {
            var insightsContent = document.querySelector(
              ".insights-block__content"
            );
            insightsContent.innerHTML = response;

            var newOffset = data.no_posts + data.no_posts_loaded;
            me.setAttribute("data-postsloaded", newOffset);

            var insightsCounter = document.querySelector(
              ".js-insights-counter"
            ).textContent;
            if (newOffset >= parseInt(insightsCounter)) {
              me.classList.add("button--hide");
              me.textContent = "Inga fler inlägg att visa";
              me.setAttribute("disabled", true);
            } else {
              me.classList.remove("button--hide");
              me.classList.add("button--loading");
              me.textContent = "Ladda fler";
              me.removeAttribute("disabled");
            }
          })
          .catch(function (error) {
            console.error("Fetch error:", error);
          });
      }
    });

    /**
     * Ajax filter categories.
     */
    document.addEventListener("click", function (event) {
      var target = event.target;

      if (target.closest(".categories-list__category a")) {
        event.preventDefault();
        var me = target;

        var categoryItem = me.parentElement;
        categoryItem.classList.add(
          "categories-list__category--active",
          "categories-list__category--loading"
        );
        var siblings = categoryItem.parentElement.children;
        Array.from(siblings).forEach(function (sibling) {
          if (sibling !== categoryItem) {
            sibling.classList.remove("categories-list__category--active");
          }
        });

        var data = {
          action: "archive_filter_terms",
          term: me.getAttribute("data-term"),
          posttype: me.getAttribute("data-posttype"),
          no_posts: me.getAttribute("data-posts"),
        };

        fetch(adminAjaxURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(data),
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            var categoryListItems = document.querySelectorAll(
              "ul.categories-list__category-filter li"
            );
            categoryListItems.forEach(function (item) {
              item.classList.remove("categories-list__category--loading");
            });

            var insightsContent = document.querySelector(
              ".insights-block__content"
            );
            insightsContent.innerHTML = response.response;

            var loadMoreButton = document.querySelector(".js-load-more");
            if (loadMoreButton) {
              loadMoreButton.setAttribute("data-term", data.term);
              loadMoreButton.setAttribute(
                "data-postsloaded",
                response.found_posts
              );
            }

            if (loadMoreButton) {
              if (
                response &&
                response.found_posts &&
                data.no_posts >= parseInt(response.found_posts)
              ) {
                loadMoreButton.classList.add("button--hide");
                loadMoreButton.textContent = "Inga fler inlägg att visa";
                loadMoreButton.setAttribute("disabled", true);
              } else {
                loadMoreButton.classList.remove("button--hide");
                loadMoreButton.textContent = "Ladda fler";
                loadMoreButton.removeAttribute("disabled");
              }
            }
          })
          .catch(function (error) {
            console.error("Fetch error:", error);
          });
      }
    });

    document.addEventListener("DOMContentLoaded", function () {
      // Initialize an array to store all audio players
      let audioPlayers = document.querySelectorAll(".js-audio-player audio");
      let audioIsPlaying = false;

      // Function to pause all audio players except the current one
      function pauseAllPlayers(currentPlayer) {
        audioPlayers.forEach(function (player) {
          if (player !== currentPlayer && !player.paused) {
            player.pause();
            let container = player.closest(".audio-player-container");
            container.classList.remove("active");
            container.querySelector(".audio-player-button", player).src =
              themePath + "/src/images/symbols/Play-Icon.svg";
          }
        });
      }

      // Event listeners for each audio player
      audioPlayers.forEach(function (player) {
        let playerContainer = player.closest(".audio-player-container"); // Add a common container class

        playerContainer.addEventListener("click", function () {
          // Pause all other players before playing the selected one
          pauseAllPlayers(player);

          if (player.paused) {
            player.play();
            audioIsPlaying = true;
            playerContainer.querySelector(
              ".audio-player-button",
              playerContainer
            ).src = themePath + "/src/images/symbols/Pause-Icon.svg";
            playerContainer.classList.add("active");
            checkPlayerStatus();
          } else {
            player.pause();
            audioIsPlaying = false;
            playerContainer.querySelector(
              ".audio-player-button",
              playerContainer
            ).src = themePath + "/src/images/symbols/Play-Icon.svg";
            playerContainer.classList.remove("active");
          }
        });

        player.ontimeupdate = function () {
          const progress = player.currentTime / player.duration;

          if (progress == 1.0) {
            audioIsPlaying = false;
            updateProgressUI(playerContainer);

            playerContainer.querySelector(
              ".audio-player-button",
              playerContainer
            ).src = themePath + "/src/images/symbols/Play-Icon.svg";
            playerContainer.classList.remove("active");
            hideAllProgressSegments(playerContainer);
          }
        };
      });

      // Checks if any player is playing and updates the UI accordingly
      function checkPlayerStatus() {
        if (audioIsPlaying) {
          audioPlayers.forEach(function (player) {
            if (!player.paused) {
              let progress = player.currentTime / player.duration;
              updateProgressUI(
                player.closest(".audio-player-container"),
                progress
              );
            }
          });

          setTimeout(function () {
            checkPlayerStatus();
          }, 33);
        }
      }

      // Updates the progress bar for a specific player
      function updateProgressUI(playerContainer, progress) {
        let progressSegment1 = playerContainer.querySelector(
          ".audio-player-progress-segment-1"
        );
        let progressSegment2 = playerContainer.querySelector(
          ".audio-player-progress-segment-2"
        );
        let progressSegment3 = playerContainer.querySelector(
          ".audio-player-progress-segment-3"
        );
        let progressSegment4 = playerContainer.querySelector(
          ".audio-player-progress-segment-4"
        );

        if (progress < 0.25) {
          progressSegment1.style.display = "block";
          let segmentProgress = -(90 - progress * 360.0);
          progressSegment1.style.transform =
            "skew(0deg," + segmentProgress + "deg)";
        } else if (progress < 0.5) {
          progressSegment1.style.transform = "skew(0deg, 0deg)";
          let segmentProgress = 180 - progress * 360.0;
          progressSegment2.style.transform =
            "skew(" + segmentProgress + "deg, 0deg)";
          progressSegment2.style.display = "block";
        } else if (progress < 0.75) {
          progressSegment2.style.transform = "skew(0deg, 0deg)";
          let segmentProgress = -(270 - progress * 360.0);
          progressSegment3.style.transform =
            "skew(0deg," + segmentProgress + "deg)";
          progressSegment3.style.display = "block";
        } else {
          progressSegment3.style.transform = "skew(0deg, 0deg)";
          let segmentProgress = 360 - progress * 360.0;
          progressSegment4.style.transform =
            "skew(" + segmentProgress + "deg, 0deg)";
          progressSegment4.style.display = "block";
        }
      }

      // Hides all progress segments for a specific player
      function hideAllProgressSegments(playerContainer) {
        let progressSegments = playerContainer.querySelectorAll(
          ".audio-player-progress-segment"
        );
        progressSegments.forEach(function (segment) {
          segment.style.display = "none";
        });
      }
    });
    function videoPopup() {

      const mediaTypeElements = document.querySelectorAll('.box');
      const popups = document.querySelectorAll('.js-popup-holder');
      const body = document.body;
    
      if (popups) {
  popups.forEach((popup) => {
    const closeButton = popup.querySelector('.js-popup-overlay');
    const popupImage = popup.querySelector('.js-popup-image');
    const popupContainer = popup.querySelector('.popup-container');

    popupImage.addEventListener('click', () => {
      popupContainer.classList.toggle('show-modal');
      popup.classList.toggle('popup-open');
      // Play video if exists
      const video = popupContainer.querySelector('video');
      if (video && video.hasAttribute('autoplay')) {
        video.play();
      }
      // Play iframe content if exists
      const iframe = popupContainer.querySelector('iframe');
      if (iframe && iframe.src.includes('autoplay=1')) {
        if (iframe.src.includes('youtube.com')) {
          // YouTube pause command
          iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
        } else if (iframe.src.includes('vimeo.com')) {
          // Vimeo pause command
          const data = { method: 'play' };
          iframe.contentWindow.postMessage(JSON.stringify(data), '*');
        }
      }
    });

    closeButton.addEventListener('click', () => {
      popupContainer.classList.toggle('show-modal');
      popup.classList.toggle('popup-open');
      // Pause video if exists
      const video = popupContainer.querySelector('video');
      if (video) {
        video.pause();
      }
      // Pause iframe content if exists
      const iframe = popupContainer.querySelector('iframe');
      if (iframe) {
        if (iframe.src.includes('youtube.com')) {
          // YouTube pause command
          iframe.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        } else if (iframe.src.includes('vimeo.com')) {
          // Vimeo pause command
          const data = { method: 'pause' };
          iframe.contentWindow.postMessage(JSON.stringify(data), '*');
        }
      }
    });
  });
}
    }
    videoPopup();  
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
